import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import DomainCard from './DomainCard'

class DomainList extends Component {
  state = {
    domain: {
      redirect: false,
      id: null
    }
  }

  handleDomainClick = id => {
    this.setState({ domain: { redirect: true, id } })
  }

  render() {
    if (this.state.domain.redirect) {
      return <Redirect push to={`/domain/${this.state.domain.id}`} />
    }
    return (
      <>
        {this.props.domains.map(domain => {
          const total = domain.concepts.length
          const finished = domain.concepts.reduce((total, concept) => {
            if (concept.finished) return ++total
            return total
          }, 0)

          return (
            <DomainCard
              key={domain.id}
              color={domain.color}
              title={domain.name}
              total={total}
              finished={finished}
              onClick={() => this.handleDomainClick(domain.id)}
            />
          )
        })}
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    domains: state.surveyState.domains
  }
}

export default connect(mapStateToProps)(DomainList)

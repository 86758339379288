import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { submitReport } from '../../actions'

import Header from './Header'
import Icon from '../Icon'
import Button from '../Button'
import ReportList from './ReportList'
import TwoLevelPie from '../TwoLevelPie'
import Modal from '../Modal'

import { calculateDomainData, getNumberOfChallenges } from '../../helpers'

class Report extends Component {
  state = {
    showSubmitModal: false,
    submitted: false,
    toDataParagraph: false
  }

  submit = () => {
    this.props.submitReport(this.props.uuid, {
      demographics: this.props.demographics,
      concepts: this.props.concepts.filter(concept => concept.finished)
    })
    this.setState({ submitted: true })
  }

  render() {
    if (this.state.submitted) return <Redirect to="/code" />
    if (this.props.concepts.length <= 0) return <Redirect to="/" />
    if (this.state.toDataParagraph) return <Redirect to="/data-paragraph" />

    return (
      <div className="report-desktop">
        <Header className="report-nav-desktop" report>
          <Link to="/">
            <Button width="180px">
              CONCEPTS <Icon name="survey" width={75} fill="#FFF" />
            </Button>
          </Link>
        </Header>
        <div className="report">
          <section className="section">
            <h1 className="title center">
              Report on {new Date().toDateString()}
            </h1>

            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                marginBottom: '10px'
              }}
            >
              <button
                className="report-print-btn"
                onClick={() => this.setState({ toDataParagraph: true })}
              >
                Print or save as pdf
              </button>
            </div>

            <div className="report-legend">
              <span className="legend-title">Legend</span>

              <div className="legend-section">
                <div className="legend-item">
                  <div className="legend-icon legend-icon--green" />
                  <span>Average Concept Rating</span>
                </div>
              </div>

              <div className="legend-section">
                <div className="legend-item">
                  <div className="legend-icon legend-icon--alt-green" />
                  <span>Check-ins</span>
                </div>
                <div className="legend-item">
                  <div className="legend-icon legend-icon--blue" />
                  <span>Hands-on Care</span>
                </div>
                <div className="legend-item">
                  <div className="legend-icon legend-icon--purple" />
                  <span>Info / Guidance</span>
                </div>
                <div className="legend-item">
                  <div className="legend-icon legend-icon--orange" />
                  <span>Care Coordination</span>
                </div>
              </div>
            </div>

            <div className="columns is-mobile is-multiline is-centered">
              <div className="column">
                <TwoLevelPie
                  title="My Living"
                  data={calculateDomainData(1, this.props.concepts)}
                  challNum={getNumberOfChallenges(1, this.props.concepts)}
                />
              </div>
              <div className="column">
                <TwoLevelPie
                  title="My Mind & Networks"
                  data={calculateDomainData(2, this.props.concepts)}
                  challNum={getNumberOfChallenges(2, this.props.concepts)}
                />
              </div>
              <div className="column">
                <TwoLevelPie
                  title="My Body"
                  data={calculateDomainData(3, this.props.concepts)}
                  challNum={getNumberOfChallenges(3, this.props.concepts)}
                />
              </div>
              <div className="column">
                <TwoLevelPie
                  title="My Self-Care"
                  data={calculateDomainData(4, this.props.concepts)}
                  challNum={getNumberOfChallenges(4, this.props.concepts)}
                />
              </div>
            </div>
          </section>
          <section className="section">
            <ReportList />
          </section>
          <div className="center" style={{ marginBottom: '25px' }}>
            <Button onClick={() => this.setState({ showSubmitModal: true })}>
              SUBMIT
            </Button>
          </div>
        </div>

        <Modal
          show={this.state.showSubmitModal}
          onClose={() => this.setState({ showSubmitModal: false })}
        >
          <div className="submit-modal">
            <h1 className="title">Submit</h1>
            <p className="subtitle">
              You won't be able to edit any of your answers or change which
              concepts you starred after submitting. You will recieve an access
              code after submission to see a read-only version of this report
              page.
              <br />
              <br />
              Are you sure you want to submit your assessment?
            </p>
            <Button loading={this.props.loading} onClick={this.submit}>
              SUBMIT & GET ACCESS CODE
            </Button>
          </div>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    concepts: state.surveyState.concepts,
    demographics: state.respondentState.demographics,
    loading: state.respondentState.loading,
    error: state.respondentState.error,
    uuid: state.surveyState.uuid
  }
}

export default connect(
  mapStateToProps,
  { submitReport }
)(Report)

import {
  INIT_RESPONDENT,
  CLEAR_RESPONDENT,
  SUBMIT,
  SUBMIT_SUCCESS,
  SUBMIT_FAILURE,
  FETCH_FROM_LOCAL_SUCCESS,
  RESTART
} from '../actions/types'

const INITIAL_STATE = {
  demographics: [],
  access_code: null,
  consented: false,
  loading: false,
  error: null
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_FROM_LOCAL_SUCCESS:
      return {
        ...state,
        consented: true,
        loading: false,
        demographics: action.payload.respondent.demographics
      }
    case INIT_RESPONDENT:
      return {
        ...state,
        consented: true,
        demographics: action.payload.formData
      }
    case SUBMIT:
      return { ...state, loading: true }
    case SUBMIT_SUCCESS:
      return {
        ...state,
        loading: false,
        access_code: action.payload.access_code
      }
    case SUBMIT_FAILURE:
      return { ...state, loading: false, error: action.payload }
    case RESTART:
      return { ...state, consented: false }
    case CLEAR_RESPONDENT:
      return { ...INITIAL_STATE, consented: false }
    default:
      return state
  }
}

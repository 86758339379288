import React from 'react'

const GlobalLoading = () => {
  return (
    <div className="global-loading">
      <div className="ring spin" />
    </div>
  )
}

export default GlobalLoading

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import ConceptCard from './ConceptCard'
import Icon from '../Icon'

class ConceptList extends Component {
  state = {
    concept: {
      redirect: false,
      id: null
    },
    goBack: false
  }

  handleConceptClick = id => {
    this.setState({ concept: { redirect: true, id } })
  }
  renderHeaderName = () => {
    if (this.props.domain) {
      return this.props.domain.name
    }
    return 'Loading...'
  }
  render() {
    if (this.state.concept.redirect) {
      return <Redirect push to={`/concept/${this.state.concept.id}`} />
    }
    if (this.state.goBack) {
      return <Redirect push to="/" />
    }
    return (
      <div className="flex-column concept-list">
        <div className="flex concept-list-header">
          <div onClick={() => this.setState({ goBack: true })}>
            <Icon name="arrow-back" width="50px" height="50px" />
          </div>

          <div className="flex-column header-text">
            <h5 className="title is-5">{this.renderHeaderName()}</h5>
            <h6 className="subtitle is-5">
              {this.props.concepts.length} Surveys
            </h6>
          </div>
        </div>
        <div className="concept-list-container">
          {this.props.concepts.map(concept => {
            return (
              <ConceptCard
                key={concept.id}
                name={concept.name}
                iconName={concept.icon_name}
                color={this.props.domain.color}
                finished={concept.finished}
                onClick={() => this.handleConceptClick(concept.id)}
              />
            )
          })}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    concepts: state.surveyState.concepts.filter(
      concept => concept.domain_id === parseInt(ownProps.match.params.id)
    ),
    domain: state.surveyState.domains.find(
      domain => domain.id === parseInt(ownProps.match.params.id)
    )
  }
}

export default connect(mapStateToProps)(ConceptList)

import React from 'react'

const RadioList = ({ label, options, name, id, handleRadioChange }) => {
  return (
    <div className="field">
      <div className="columns">
        <div className="column is-2">
          <label className="label">{name}</label>
        </div>
        <div className="column">
          <div className="control control-stacked">
            {options.map(item => {
              return (
                <label className="radio" key={item.id}>
                  <input
                    type="radio"
                    name={name}
                    value={item.text}
                    onChange={() => handleRadioChange(id, item.text)}
                    required
                  />
                  {item.text}
                </label>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default RadioList

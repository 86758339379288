import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'

import Icon from '../Icon'

const Header = ({ subtitle, iconName, route }) => {
  const [toReport, reportIconClick] = useState(false)

  if (toReport && route) return <Redirect push to={route} />

  return (
    <div className="flex mobile-header">
      <div className="flex-column header-text">
        <h5 className="title is-5">My Strengths + My Health</h5>
        <h6 className="subtitle is-5">{subtitle}</h6>
      </div>
      <div onClick={() => reportIconClick(true)}>
        <Icon name={iconName} width="50px" height="50px" />
      </div>
    </div>
  )
}

export default Header

import React from 'react'

const colorMap = [
  '#C4C4C4',
  '#FF3860',
  '#FF3860',
  '#FED11A',
  '#23D160',
  '#23D160'
]

const RatingSlider = ({ onChange, rating, ratingText, immutable }) => {
  const ratingMap = ['No Rating', ...ratingText.split('|')]
  if (immutable) {
    return (
      <div className="rating-slider-container">
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '0 25px'
          }}
        >
          <div className="slider immutable">
            <div className={`slider-dot${rating >= 1 ? ' active' : ''}`} />
            <div className={`slider-dot${rating >= 2 ? ' active' : ''}`} />
            <div className={`slider-dot${rating >= 3 ? ' active' : ''}`} />
            <div className={`slider-dot${rating >= 4 ? ' active' : ''}`} />
            <div className={`slider-dot${rating >= 5 ? ' active' : ''}`} />
          </div>
          <div
            className="rating-description immutable"
            style={{ backgroundColor: rating ? colorMap[rating] : colorMap[0] }}
          >
            {rating ? ratingMap[rating] : ratingMap[0]}
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className="rating-slider-container">
      <span>{ratingMap[1]}</span>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '0 25px'
        }}
      >
        <div className="slider">
          <div
            className={`slider-dot${rating >= 1 ? ' active' : ''}`}
            onClick={() => onChange(1)}
          />
          <div
            className={`slider-dot${rating >= 2 ? ' active' : ''}`}
            onClick={() => onChange(2)}
          />
          <div
            className={`slider-dot${rating >= 3 ? ' active' : ''}`}
            onClick={() => onChange(3)}
          />
          <div
            className={`slider-dot${rating >= 4 ? ' active' : ''}`}
            onClick={() => onChange(4)}
          />
          <div
            className={`slider-dot${rating >= 5 ? ' active' : ''}`}
            onClick={() => onChange(5)}
          />
        </div>
        <div
          className="rating-description"
          style={{ backgroundColor: rating ? colorMap[rating] : colorMap[0] }}
        >
          {rating ? ratingMap[rating] : ratingMap[0]}
        </div>
      </div>
      <span>{ratingMap[5]}</span>
    </div>
  )
}

export default RatingSlider

import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { fetchSummary } from '../actions'

import Button from './Button'

const SummaryCode = ({ fetchSummary, summary, loading, error }) => {
  const [accessCode, setAccessCode] = useState('')

  if (Object.keys(summary).length > 0) return <Redirect push to="/summary" />

  return (
    <div className="summary-code">
      <div className="field">
        <label className="label">
          Enter your Access Code you received on your submission page below
        </label>
        <div className="control">
          <input
            className="input is-medium is-rounded"
            type="text"
            placeholder="Access Code"
            value={accessCode}
            onChange={e => setAccessCode(e.target.value)}
          />
        </div>
        {error && <p className="help is-danger">{error.response.data}</p>}
      </div>
      <Button
        onClick={() => fetchSummary(accessCode)}
        loading={loading}
        disabled={!accessCode}
      >
        Retrieve Report
      </Button>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    summary: state.summaryState.summary,
    loading: state.summaryState.loading,
    error: state.summaryState.error
  }
}

export default connect(
  mapStateToProps,
  { fetchSummary }
)(SummaryCode)

import React, { useState } from 'react'
import { Link } from 'react-router-dom'

const RenderSurveyList = ({ domains }) => {
  const [url, setUrl] = useState(window.location.pathname)

  return domains.map(domain => {
    return (
      <React.Fragment key={domain.id}>
        <p className="menu-label">{domain.name}</p>
        <ul className="menu-list">
          {domain.concepts.map(concept => {
            return (
              <li key={concept.id}>
                <Link
                  to={`/concept/${concept.id}`}
                  className={
                    (url === `/concept/${concept.id}` ? 'is-active' : '') +
                    (concept.finished ? ' is-finished' : '')
                  }
                  onClick={() => setUrl(`/concept/${concept.id}`)}
                >
                  {concept.name}
                </Link>
              </li>
            )
          })}
        </ul>
      </React.Fragment>
    )
  })
}

const Menu = ({ className, domains }) => {
  return (
    <div className={`${className}`}>
      <aside className="menu">
        <RenderSurveyList domains={domains} />
      </aside>
      <footer className="menu-footer">
        <p>
          © {new Date().getFullYear()} Regents of the University of Minnesota.
          All rights reserved.
        </p>
      </footer>
    </div>
  )
}

export default Menu

import React from 'react'

import Button from './Button'
import { ReactComponent as PrimaryLogo } from '../assets/MSMH_Primary_Logo.svg'
import { ReactComponent as CircleLogo } from '../assets/MSMH_Circle_Logo.svg'
import Devices from '../assets/Devices.png'
import Laptop from '../assets/Laptop.png'

const LandingPage = () => {
  return (
    <div className="landing-page">
      <nav className="level landing-nav" style={{ marginBottom: '0px' }}>
        <div className="level-left">
          <div className="level-item">
            <PrimaryLogo width={350} height={70} />
          </div>
        </div>
        <div className="level-right">
          <div className="level-item">
            <Button
              onClick={() =>
                window.open(
                  'http://license.umn.edu/technologies/20180076_mystrengths-myhealth'
                )
              }
            >
              Obtain License
            </Button>
          </div>
        </div>
      </nav>

      <div className="landing-content">
        <section className="feature-container">
          <div className="feature-text">
            <h1 className="title">
              Self-identify health strengths, challenges and needs
            </h1>
            <p>
              MyStrengths MyHealth™ (MSMH) is a whole-person strengths-based
              consumer-facing mobile enhanced application designed for
              individuals, families, and communities to self-identify strengths,
              challenges, and needs. MSMH leverages the rigor of the Omaha
              System (Martin, 2005), a multi-disciplinary standardized health
              terminology and valid, reliable instrument that addresses all of
              health across four domains with 42 discrete concepts. Surveys for
              each of the 42 concepts are embedded using expert- and
              community-validated plain language terms. For example, in MSMH,
              the Omaha System domains, Environmental, Psychosocial,
              Physiological, and Health-related Behaviors, were renamed My
              Living, My Mind and Network, My Body, and My Self-care. In MSMH,
              signs/symptoms associated with the 42 concepts were renamed
              Challenges, and interventions were renamed Needs. MSMH captures
              health status using a continuum of severity for each concept (1
              star = very bad; 5 stars = great). On this scale, a concept is
              considered to be a strength if it is rated a 4 (good) or 5
              (great). This community-friendly, consumer-facing instrument
              generates powerful data for clinical use, education, and research.
            </p>
          </div>
          <div className="feature-image">
            <img src={Devices} alt="Screenshots" />
          </div>
        </section>

        <section className="feature-container reverse">
          <div className="feature-text">
            <h1 className="title">Consumer-focused health management</h1>
            <p>
              Consumer-focused technologies, such as mobile health apps, engage
              consumers in individual health management and also support
              population health prevention and promotion. These technologies,
              and the data generated from their use, have the potential to
              transform healthcare and population health. Engaging individuals
              and families to contribute consumer-generated data generated
              through MyStrengths MyHealth (MSMH) facilitates deeper levels of
              participation in care and provides a foundation for personalized
              and collaborative care.
            </p>
          </div>
          <div className="feature-image">
            <img src={Laptop} alt="Screenshots" />
          </div>
        </section>

        <section className="feature-container">
          <div className="feature-text">
            <h1 className="title">Info</h1>
            <h4 className="title is-4" style={{ margin: '1.5em 0 0.5em 0' }}>
              Benefits
            </h4>
            <ul>
              <li>
                Easy for patients to comprehend questions and complete survey -
              </li>
              <li>Comprehensive survey of health related domains -</li>
            </ul>

            <h4 className="title is-4" style={{ margin: '1.5em 0 0.5em 0' }}>
              Features
            </h4>
            <ul>
              <li>Web-based, consumer-facing mobile application -</li>
              <li>42 MSMH concepts across 4 domains of health -</li>
              <li>Available in English language only -</li>
              <li>Community validated plain language -</li>
            </ul>

            <h4 className="title is-4" style={{ margin: '1.5em 0 0.5em 0' }}>
              Applications
            </h4>
            <ul>
              <li>Health tracking -</li>
              <li>Health assessment -</li>
              <li>Public health research -</li>
            </ul>
          </div>
          <div className="feature-image logo">
            <CircleLogo />
          </div>
        </section>
      </div>
    </div>
  )
}

export default LandingPage

import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import Button from './Button'

const DataParagraph = ({ domains, concepts }) => {
  const [toReport, setToReport] = useState(false)

  if (toReport) return <Redirect to="/report" />

  const renderStrengths = domainConcepts => {
    return domainConcepts
      .filter(concept => concept.rating >= 4)
      .map(concept => concept.name)
  }
  const renderChallenges = domainConcepts => {
    const concepts = {}
    domainConcepts.forEach(concept => {
      concept.challenges.forEach(challenge => {
        if (challenge.selected) {
          concepts[concept.name]
            ? concepts[concept.name].push(challenge.text)
            : (concepts[concept.name] = [challenge.text])
        }
      })
    })
    return concepts
  }
  const getChallengesCount = domainConcepts => {
    let total = 0
    domainConcepts.forEach(concept => {
      concept.challenges.forEach(challenge => {
        if (challenge.selected) total++
      })
    })
    return total
  }
  const renderNeeds = domainConcepts => {
    const needs = {
      'Check-ins': [],
      'Info / Guidance': [],
      'Hands-on Care': [],
      'Care Coordination': []
    }
    domainConcepts.forEach(concept => {
      concept.needs.forEach(need => {
        if (need.selected) {
          needs[need.text].push(concept.name)
        }
      })
    })
    return needs
  }
  const getNeedsCount = domainConcepts => {
    let total = 0
    domainConcepts.forEach(concept => {
      concept.needs.forEach(need => {
        if (need.selected) total++
      })
    })
    return total
  }

  const domainData = (() => {
    return domains.map(({ id, name, color }) => {
      const domainConcepts = concepts.filter(
        concept => concept.domain_id === id
      )
      const strengths = renderStrengths(domainConcepts)
      const strengthPercent = strengths.length / domainConcepts.length
      const challenges = renderChallenges(domainConcepts)
      const challengesCount = getChallengesCount(domainConcepts)
      const needs = renderNeeds(domainConcepts)
      const needsCount = getNeedsCount(domainConcepts)

      return {
        id,
        name,
        color,
        strengthPercent,
        strengths,
        challenges,
        challengesCount,
        needs,
        needsCount
      }
    })
  })()

  return (
    <table className="data-paragraph">
      <colgroup>
        <col className="col-1" />
        <col className="col-2" />
        <col className="col-3" />
        <col className="col-4" />
      </colgroup>
      <thead>
        <tr>
          <th>Domain</th>
          <th>Strengths</th>
          <th>Challenges</th>
          <th>Needs</th>
        </tr>
      </thead>
      <tbody>
        {domainData.map(
          ({
            id,
            name,
            color,
            strengthPercent,
            strengths,
            challenges,
            challengesCount,
            needs,
            needsCount
          }) => {
            return (
              <tr key={id}>
                <td>
                  <h3
                    style={{
                      margin: 0,
                      color,
                      fontSize: '18px',
                      fontWeight: '600'
                    }}
                  >
                    {name}
                  </h3>
                  <p>
                    <b>{(strengthPercent * 100).toFixed(2)}%</b> Strengths
                  </p>
                  <p>
                    <b>{challengesCount}</b> challenges
                  </p>
                  <p>
                    <b>{needsCount}</b> needs
                  </p>
                </td>
                <td>
                  <b>Strengths</b>:{' '}
                  {strengths.map(name => (
                    <p key={name}>{name}</p>
                  ))}
                </td>
                <td>
                  {Object.entries(challenges)
                    .filter(([_, chals]) => chals.length > 0)
                    .map(([name, chals]) => (
                      <p key={name}>
                        <b>{name}</b>: {chals.join(', ')}
                      </p>
                    ))}
                </td>
                <td>
                  {Object.entries(needs)
                    .filter(([_, concepts]) => concepts.length > 0)
                    .map(([name, concepts]) => (
                      <p key={name}>
                        <b>{name}</b>: {concepts.join(', ')}
                      </p>
                    ))}
                </td>
              </tr>
            )
          }
        )}
      </tbody>

      <tfoot className="data-paragraph-button">
        <tr>
          <td>
            <Button onClick={() => setToReport(true)}>Go Back</Button>
          </td>
        </tr>
      </tfoot>
    </table>
  )
}

const mapStateToProps = state => {
  return {
    domains: state.surveyState.domains,
    concepts: state.surveyState.concepts
  }
}

export default connect(mapStateToProps)(DataParagraph)

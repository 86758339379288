import React from 'react'

import Button from '../Button'

const FinishedConcept = ({ id, color, onClick }) => {
  return (
    <div className="concept-container concept-finished">
      <h5 className="title is-5">Thank you for completing this concept!</h5>
      <h6 className="subtitle">
        You can edit your answers by clicking the edit button below
      </h6>
      <div className="concept-btn-container center">
        <Button color={color} onClick={() => onClick(id)}>
          EDIT
        </Button>
      </div>
    </div>
  )
}

export default FinishedConcept

import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware, compose } from 'redux'
import reduxThunk from 'redux-thunk'
import reducers from './reducers'

import App from './components/App'
import NoUuid from './components/NoUuid'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(reduxThunk))
)

const parseUuid = () => {
  // Parse UUID out of url to send to router
  let uuid = ''
  const indexOfSlash = window.location.pathname.replace('/', '').indexOf('/')
  if (indexOfSlash === -1) {
    uuid = window.location.pathname.replace('/', '')
  } else {
    uuid = window.location.pathname.replace('/', '').substring(0, indexOfSlash)
  }

  if (uuid === '') {
    uuid = 'nouuid'
  }
  return uuid
}

const uuid = parseUuid()

ReactDOM.render(
  <Provider store={store}>
    {uuid === 'nouuid' || uuid === 'summary' || uuid === 'summaries' ? (
      <NoUuid />
    ) : (
      <App uuid={uuid} />
    )}
  </Provider>,
  document.querySelector('#root')
)

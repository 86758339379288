import {
  FETCH_SURVEY,
  FETCH_SURVEY_SUCCESS,
  FETCH_SURVEY_FAILURE,
  FONT_SIZE_CHANGED,
  RATING_CHANGED,
  CHALLENGE_CHANGED,
  NEED_CHANGED,
  CONCEPT_FINISHED,
  CONCEPT_EDITING,
  FETCH_FROM_LOCAL,
  FETCH_FROM_LOCAL_SUCCESS,
  FETCH_FROM_LOCAL_FAILURE,
  CONCEPT_STARRED,
  CLEAR_RESPONDENT
} from '../actions/types'

const INITIAL_STATE = {
  uuid: '',
  concepts: [],
  domains: [],
  nextConceptId: 1,
  surveyFinished: false,
  fontSize: 'regular',
  loading: false,
  error: null
}

const finishConcept = (id, uuid, concepts, domains) => {
  const conceptIdx = concepts.findIndex(item => item.id === id)
  concepts[conceptIdx].finished = true
  // If the concept was finished but not rated set the rating to 0
  concepts[conceptIdx].rating = concepts[conceptIdx].rating
    ? concepts[conceptIdx].rating
    : 0

  let idxArr
  domains.forEach((domain, idx) => {
    let cIdx = domain.concepts.findIndex(concept => concept.id === id)
    if (cIdx >= 0) {
      idxArr = [idx, cIdx]
    }
  })
  // Finished the concept nested under the domain
  domains[idxArr[0]].concepts[idxArr[1]].finished = true

  const surveyFinished = concepts.every(concept => concept.finished === true)

  // Update localStorage with new state object
  const survey = JSON.parse(localStorage.getItem('survey'))
  survey.uuid = uuid
  survey.concepts = concepts
  survey.domains = domains
  survey.surveyFinished = surveyFinished
  localStorage.setItem('survey', JSON.stringify(survey))

  if (conceptIdx + 1 < concepts.length) {
    return {
      concepts,
      domains,
      nextConceptId: concepts[conceptIdx + 1].id,
      surveyFinished
    }
  } else {
    return { concepts, domains, nextConceptId: 1, surveyFinished }
  }
}

const changeRating = (id, concepts, rating) => {
  const conceptIdx = concepts.findIndex(item => item.id === id)
  concepts[conceptIdx].rating = rating
  return concepts
}

const changeChallenge = (id, concepts, challenge_id) => {
  const conceptIdx = concepts.findIndex(item => item.id === id)
  const challengeIdx = concepts[conceptIdx].challenges.findIndex(
    challenge => challenge.id === challenge_id
  )
  concepts[conceptIdx].challenges[challengeIdx].selected = !concepts[conceptIdx]
    .challenges[challengeIdx].selected
  return concepts
}

const changeNeed = (id, concepts, needIdx) => {
  const conceptIdx = concepts.findIndex(item => item.id === id)

  concepts[conceptIdx].needs[needIdx].selected = !concepts[conceptIdx].needs[
    needIdx
  ].selected

  return concepts
}

const editConcept = (id, concepts, domains) => {
  const conceptIdx = concepts.findIndex(item => item.id === id)
  concepts[conceptIdx].finished = false

  let idxArr
  domains.forEach((domain, idx) => {
    let cIdx = domain.concepts.findIndex(concept => concept.id === id)
    if (cIdx >= 0) {
      idxArr = [idx, cIdx]
    }
  })
  domains[idxArr[0]].concepts[idxArr[1]].finished = false

  return { domains, concepts, surveyFinished: false }
}

const starConcept = (id, concepts) => {
  const conceptIdx = concepts.findIndex(item => item.id === id)
  concepts[conceptIdx].starred = !concepts[conceptIdx].starred
  return concepts
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_FROM_LOCAL:
      return { ...state, loading: true }
    case FETCH_FROM_LOCAL_SUCCESS:
      const { uuid, concepts, domains } = action.payload.survey
      return {
        ...state,
        loading: false,
        uuid,
        concepts,
        domains
      }
    case FETCH_FROM_LOCAL_FAILURE:
      return { ...state, loading: false, error: action.payload }
    case FETCH_SURVEY:
      return { ...state, loading: true }
    case FETCH_SURVEY_SUCCESS:
      return {
        ...state,
        loading: false,
        uuid: action.payload.uuid,
        concepts: action.payload.data.concepts,
        domains: action.payload.data.domains
      }
    case FETCH_SURVEY_FAILURE:
      return { ...state, loading: false, error: action.payload }
    case FONT_SIZE_CHANGED:
      return { ...state, fontSize: action.payload }
    case RATING_CHANGED:
      return {
        ...state,
        concepts: changeRating(
          action.payload.id,
          [...state.concepts],
          action.payload.rating
        )
      }
    case CHALLENGE_CHANGED:
      return {
        ...state,
        concepts: changeChallenge(
          action.payload.id,
          [...state.concepts],
          action.payload.challenge_id
        )
      }
    case NEED_CHANGED:
      return {
        ...state,
        concepts: changeNeed(
          action.payload.id,
          [...state.concepts],
          action.payload.needIdx
        )
      }
    case CONCEPT_FINISHED:
      const conceptFinishedState = finishConcept(
        action.payload.id,
        state.uuid,
        [...state.concepts],
        [...state.domains]
      )
      return { ...state, ...conceptFinishedState }
    case CONCEPT_EDITING:
      const conceptEditedState = editConcept(
        action.payload.id,
        [...state.concepts],
        [...state.domains]
      )
      return { ...state, ...conceptEditedState }
    case CONCEPT_STARRED:
      return {
        ...state,
        concepts: starConcept(action.payload.id, [...state.concepts])
      }
    case CLEAR_RESPONDENT:
      return { ...INITIAL_STATE, loading: state.loading }
    default:
      return state
  }
}

import api from '../api'
import {
  INIT_RESPONDENT,
  CLEAR_RESPONDENT,
  FETCH_SURVEY,
  FETCH_SURVEY_SUCCESS,
  FETCH_SURVEY_FAILURE,
  FONT_SIZE_CHANGED,
  RATING_CHANGED,
  NEED_CHANGED,
  CONCEPT_FINISHED,
  CONCEPT_EDITING,
  CONCEPT_STARRED,
  SUBMIT,
  SUBMIT_SUCCESS,
  SUBMIT_FAILURE,
  FETCH_FROM_LOCAL,
  FETCH_FROM_LOCAL_SUCCESS,
  FETCH_FROM_LOCAL_FAILURE,
  CHALLENGE_CHANGED,
  RESTART,
  FETCH_SUMMARY,
  FETCH_SUMMARY_SUCCESS,
  FETCH_SUMMARY_FAILURE
} from './types'

export const fetchSurvey = (
  uuid = '8fc01d6e-b0a8-5518-ad69-63d773a621cf'
) => async dispatch => {
  dispatch({ type: FETCH_SURVEY })

  try {
    const response = await api.get(`/survey?uuid=${uuid}`)
    dispatch({
      type: FETCH_SURVEY_SUCCESS,
      payload: { uuid, data: response.data }
    })
  } catch (err) {
    dispatch({ type: FETCH_SURVEY_FAILURE, payload: err })
  }
}

export const fetchFromLocal = uuid => dispatch => {
  dispatch({ type: FETCH_FROM_LOCAL })

  try {
    const localState = {
      respondent: JSON.parse(localStorage.getItem('respondent')),
      survey: JSON.parse(localStorage.getItem('survey'))
    }

    if (localState.survey.uuid !== uuid) {
      dispatch({
        type: FETCH_FROM_LOCAL_FAILURE,
        payload: { status: 400, data: 'UUID does not match' }
      })
    } else {
      dispatch({ type: FETCH_FROM_LOCAL_SUCCESS, payload: localState })
    }
  } catch (err) {
    dispatch({ type: FETCH_FROM_LOCAL_FAILURE, payload: err })
  }
}

export const clearRespondent = () => {
  localStorage.clear()
  return { type: CLEAR_RESPONDENT }
}

export const initRespondent = (formData, surveyData) => {
  const respondent = {
    consented: true,
    demographics: formData
  }
  // Initialize localStorage with respondent and survey data
  localStorage.setItem('respondent', JSON.stringify(respondent))
  localStorage.setItem('survey', JSON.stringify(surveyData))
  return { type: INIT_RESPONDENT, payload: { formData } }
}

export const fontSizeChanged = size => {
  return { type: FONT_SIZE_CHANGED, payload: size }
}

export const ratingChanged = (id, rating) => {
  return { type: RATING_CHANGED, payload: { id, rating } }
}

export const challengeChanged = (id, challenge_id) => {
  return {
    type: CHALLENGE_CHANGED,
    payload: { id, challenge_id: parseInt(challenge_id) }
  }
}

export const needChanged = (id, needIdx) => {
  return { type: NEED_CHANGED, payload: { id, needIdx } }
}

export const conceptFinished = id => {
  return { type: CONCEPT_FINISHED, payload: { id } }
}

export const conceptEditing = id => {
  return { type: CONCEPT_EDITING, payload: { id } }
}

export const conceptStarred = id => {
  return { type: CONCEPT_STARRED, payload: { id } }
}

export const submitReport = (
  uuid = '8fc01d6e-b0a8-5518-ad69-63d773a621cf',
  submission
) => async dispatch => {
  dispatch({ type: SUBMIT })

  try {
    const ipRes = await api.get('https://api.ipify.org?format=json')
    const ip = ipRes.data.ip
    const response = await api.post(`/submit?uuid=${uuid}`, {
      ip,
      ...submission
    })
    dispatch({ type: SUBMIT_SUCCESS, payload: response.data })
  } catch (err) {
    dispatch({ type: SUBMIT_FAILURE, payload: err.response })
  }
}

export const restart = () => {
  localStorage.clear('survey')
  localStorage.clear('respondent')
  return { type: RESTART }
}

export const fetchSummary = accessCode => async dispatch => {
  dispatch({ type: FETCH_SUMMARY })

  try {
    const response = await api.get(`/summary?access_code=${accessCode}`)
    dispatch({ type: FETCH_SUMMARY_SUCCESS, payload: response.data })
  } catch (err) {
    dispatch({ type: FETCH_SUMMARY_FAILURE, payload: err })
  }
}

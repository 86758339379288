import React from 'react'
import { connect } from 'react-redux'

const InvalidUuid = ({ err }) => {
  return (
    <div style={{ padding: '25px' }}>
      {err && (
        <article className="message is-danger">
          <div className="message-header">
            <p>Error</p>
          </div>
          <div className="message-body">
            {(err && err.response.data) ||
              'There has been an error, please check your network connection'}
          </div>
        </article>
      )}
      To purchase a license to this software go to{' '}
      <a
        href="http://license.umn.edu/technologies/20180076_mystrengths-myhealth"
        target="_blank"
        rel="noopener noreferrer"
      >
        umn.edu
      </a>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    err: state.surveyState.error
  }
}

export default connect(mapStateToProps)(InvalidUuid)

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { fetchSurvey, fetchFromLocal } from '../actions'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import Consent from './Consent'
import Container from './Container'
import IntroSurvey from './IntroSurvey'
import InvalidUuid from './InvalidUuid'

import './App.css'

class App extends Component {
  componentWillMount() {
    // Check if there is in-progress concepts to keep state during refreshes
    // and keep unfinished reports
    if (localStorage.getItem('respondent') && localStorage.getItem('survey')) {
      this.props.fetchFromLocal(this.props.uuid)
    } else {
      this.props.fetchSurvey(this.props.uuid)
    }
  }
  render() {
    if (this.props.err) return <InvalidUuid />

    return (
      <Router basename={this.props.uuid}>
        <Switch>
          <Route path="/consent" component={Consent} />
          <Route path="/intro-survey" component={IntroSurvey} />
          <Route
            path="/"
            render={props => <Container {...props} uuid={this.props.uuid} />}
          />
        </Switch>
      </Router>
    )
  }
}

const mapStateToProps = state => {
  return {
    err: state.surveyState.error
  }
}

export default connect(
  mapStateToProps,
  { fetchSurvey, fetchFromLocal }
)(App)

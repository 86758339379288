import { combineReducers } from 'redux'
import surveyReducer from './surveyReducer'
import respondentReducer from './respondentReducer'
import summaryReducer from './summaryReducer'

export default combineReducers({
  surveyState: surveyReducer,
  respondentState: respondentReducer,
  summaryState: summaryReducer
})

import React from 'react'
import { ResponsiveContainer, PieChart, Pie, Tooltip } from 'recharts'

const TwoLevelPie = ({ title, data, challNum }) => {
  return (
    <div className="two-level-pie">
      <h5 className="title is-5 text-center">{title}</h5>
      <ResponsiveContainer>
        <PieChart>
          <Pie
            data={data.ratingData}
            dataKey="value"
            cx={200}
            cy={200}
            outerRadius={60}
            fill="#8884d8"
          />
          <Pie
            data={data.needData}
            dataKey="value"
            cx={200}
            cy={200}
            innerRadius={70}
            outerRadius={90}
            label
          />
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
      <div className="challenge-count">
        Total number of challenges <div>{challNum}</div>
      </div>
    </div>
  )
}

export default TwoLevelPie

import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { submitReport, conceptStarred } from '../../actions'

import Header from './Header'
import Button from '../Button'
import Icon from '../Icon'
import ReportCard from './ReportCard'
import TwoLevelPie from '../TwoLevelPie'

import { calculateDomainData, getNumberOfChallenges } from '../../helpers'

const sortTypes = {
  BEST_RATING: 'BEST_RATING',
  WORST_RATING: 'WORST_RATING'
}

class Report extends Component {
  state = {
    submitted: false,
    sortType: sortTypes.WORST_RATING
  }

  compare(a, b) {
    if (a > b) {
      return 1
    } else if (a < b) {
      return -1
    } else {
      return 0
    }
  }

  submit = () => {
    this.props.submitReport()
    this.setState({ submitted: true })
  }

  changeSortType = () => {
    if (this.state.sortType === sortTypes.BEST_RATING) {
      this.setState({ sortType: sortTypes.WORST_RATING })
    } else {
      this.setState({ sortType: sortTypes.BEST_RATING })
    }
  }

  renderReportList = () => {
    if (!this.props.concepts.some(concept => concept.finished === true)) {
      return (
        <div className="report-card">
          <div className="card-title">
            <h5 className="title is-5">No Surveys Finished</h5>
          </div>
        </div>
      )
    }

    switch (this.state.sortType) {
      case sortTypes.WORST_RATING:
        return this.props.concepts
          .filter(concept => concept.finished === true)
          .sort((a, b) => this.compare(a.rating, b.rating))
          .map(concept => {
            return (
              <ReportCard
                concept={concept}
                key={concept.id}
                onStar={() => this.props.conceptStarred(concept.id)}
              />
            )
          })
      case sortTypes.BEST_RATING:
        return this.props.concepts
          .filter(concept => concept.finished === true)
          .sort((a, b) => this.compare(b.rating, a.rating))
          .map(concept => {
            return (
              <ReportCard
                concept={concept}
                key={concept.id}
                onStar={() => this.props.conceptStarred(concept.id)}
              />
            )
          })
      default:
        return this.props.concepts
          .filter(concept => concept.finished === true)
          .map(concept => {
            return (
              <ReportCard
                concept={concept}
                key={concept.id}
                onStar={() => this.props.conceptStarred(concept.id)}
              />
            )
          })
    }
  }

  renderSortIcon = () => {
    switch (this.state.sortType) {
      case sortTypes.BEST_RATING:
        return (
          <Icon
            name="arrow-dropup"
            height="25px"
            width="25px"
            style={{ marginLeft: '10px' }}
            fill="#FFF"
          />
        )
      case sortTypes.WORST_RATING:
        return (
          <Icon
            name="arrow-dropdown"
            height="25px"
            width="25px"
            style={{ marginLeft: '10px' }}
            fill="#FFF"
          />
        )
      default:
        return (
          <Icon
            name="arrow-dropup"
            height="25px"
            width="25px"
            style={{ marginLeft: '10px' }}
            fill="#FFF"
          />
        )
    }
  }

  render() {
    if (this.state.submitted) return <Redirect push to="/code" />
    if (this.props.concepts.length <= 0) return <Redirect to="/" />

    return (
      <div className="mobile-report">
        <Header subtitle="Report Page" iconName="survey" route="/" />
        <div className="report-content">
          <h1 className="title center">
            Report on {new Date().toDateString()}
          </h1>

          <div className="report-legend">
            <span className="legend-title">Legend</span>

            <div className="legend-section">
              <div className="legend-item">
                <div className="legend-icon legend-icon--green" />
                <span>Average Concept Rating</span>
              </div>
            </div>

            <div className="legend-section">
              <div className="legend-item">
                <div className="legend-icon legend-icon--alt-green" />
                <span>Check-ins</span>
              </div>
              <div className="legend-item">
                <div className="legend-icon legend-icon--blue" />
                <span>Hands-on Care</span>
              </div>
              <div className="legend-item">
                <div className="legend-icon legend-icon--purple" />
                <span>Info / Guidance</span>
              </div>
              <div className="legend-item">
                <div className="legend-icon legend-icon--orange" />
                <span>Care Coordination</span>
              </div>
            </div>
          </div>

          <div className="columns is-mobile is-multiline is-centered">
            <div className="column">
              <TwoLevelPie
                title="My Living"
                data={calculateDomainData(1, this.props.concepts)}
                challNum={getNumberOfChallenges(1, this.props.concepts)}
              />
            </div>
            <div className="column">
              <TwoLevelPie
                title="My Mind & Networks"
                data={calculateDomainData(2, this.props.concepts)}
                challNum={getNumberOfChallenges(2, this.props.concepts)}
              />
            </div>
            <div className="column">
              <TwoLevelPie
                title="My Body"
                data={calculateDomainData(3, this.props.concepts)}
                challNum={getNumberOfChallenges(3, this.props.concepts)}
              />
            </div>
            <div className="column">
              <TwoLevelPie
                title="My Self-Care"
                data={calculateDomainData(4, this.props.concepts)}
                challNum={getNumberOfChallenges(4, this.props.concepts)}
              />
            </div>
          </div>

          <div className="mobile-report-sort" onClick={this.changeSortType}>
            Sort By Rating {this.renderSortIcon()}
          </div>
          <div className="mobile-report-list">{this.renderReportList()}</div>

          <Button loading={this.props.loading} onClick={this.submit}>
            SUBMIT & GET ACCESS CODE
          </Button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    concepts: state.surveyState.concepts,
    loading: state.respondentState.loading,
    error: state.respondentState.error
  }
}

export default connect(
  mapStateToProps,
  { submitReport, conceptStarred }
)(Report)

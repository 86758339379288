import {
  FETCH_SUMMARY,
  FETCH_SUMMARY_SUCCESS,
  FETCH_SUMMARY_FAILURE
} from '../actions/types'

const INITIAL_STATE = {
  summary: [],
  loading: false,
  error: null
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_SUMMARY:
      return { ...state, error: null, loading: true }
    case FETCH_SUMMARY_SUCCESS:
      return { ...state, summary: action.payload, loading: false }
    case FETCH_SUMMARY_FAILURE:
      return { ...state, error: action.payload, loading: false }
    default:
      return state
  }
}

import React from 'react'
import { Link } from 'react-router-dom'

import Button from '../Button'
import Icon from '../Icon'

const FinishedModal = () => {
  return (
    <div className="finished-modal center">
      <h1 className="title">Thank you for completing all concepts!</h1>
      <h2 className="subtitle">
        Please go to the report page to see your final report and submit.
      </h2>
      <Link to="/report">
        <Button width="170px">
          REPORT <Icon name="report" width={75} fill="#FFF" />
        </Button>
      </Link>
    </div>
  )
}

export default FinishedModal

import React from 'react'

import Header from './Header'
import Button from '../Button'
import Icon from '../Icon'

const renderNavSkeleton = () => {
  return (
    <Header className="nav-desktop">
      <Button width="180px">
        CONCEPTS <Icon name="survey" width={75} fill="#FFF" />
      </Button>
    </Header>
  )
}

const renderGraphSkeleton = () => {
  return (
    <section className="section">
      <div className="columns">
        <div className="column center">
          <div className="skeleton graph-skel" />
        </div>
        <div className="column center">
          <div className="skeleton graph-skel" />
        </div>
      </div>
    </section>
  )
}

const renderConceptListSkeleton = () => {
  return (
    <section className="section center">
      <div className="skeleton header-skel" />
      <div className="skeleton txt-skel" />
      <div className="skeleton txt-skel" />
      <div className="skeleton txt-skel" />
      <div className="skeleton txt-skel" />
      <div className="skeleton txt-skel" />
    </section>
  )
}

const ReportSkeleton = () => {
  return (
    <div className="report-desktop">
      {renderNavSkeleton()}
      <div className="report">
        {renderGraphSkeleton()}
        {renderConceptListSkeleton()}
      </div>
    </div>
  )
}

export default ReportSkeleton

import React from 'react'

const Button = ({
  color = '#0CA7D3',
  width = '335px',
  children,
  onClick,
  loading,
  disabled
}) => {
  if (loading) {
    return (
      <button
        style={{ backgroundColor: color, width: width }}
        className="button is-rounded is-primary is-medium"
        onClick={onClick}
        disabled
      >
        <div className="ring spin" />
      </button>
    )
  }

  return (
    <button
      style={{ backgroundColor: color, width: width }}
      className="button is-rounded is-primary is-medium"
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  )
}

export default Button

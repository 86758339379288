import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import {
  ratingChanged,
  challengeChanged,
  needChanged,
  conceptFinished,
  conceptEditing
} from '../../actions'

import Icon from '../Icon'
import Button from '../Button'
import GlobalLoading from '../GlobalLoading'
import RatingSlider from '../RatingSlider'
import NeedSelection from '../NeedSelection'
import CheckboxList from '../CheckboxList'
import FinishedConcept from './FinishedConcept'

class Concept extends Component {
  state = {
    goBack: false
  }

  renderConcept = (concept, color) => {
    if (concept.finished)
      return (
        <FinishedConcept
          id={concept.id}
          color={color}
          onClick={this.props.conceptEditing}
        />
      )

    return (
      <div className="concept-container">
        <h5
          className="title is-5"
          style={{ textAlign: 'center', padding: '15px 0px' }}
        >
          How would you describe your {concept.name}?
        </h5>
        <RatingSlider
          rating={concept.rating}
          ratingText={concept.rating_text}
          onChange={rating => this.props.ratingChanged(concept.id, rating)}
        />
        <CheckboxList
          challenges={concept.challenges}
          onChange={e =>
            this.props.challengeChanged(concept.id, e.target.dataset.id)
          }
          mobile
        />
        <NeedSelection
          name={concept.name}
          needs={concept.needs}
          onChange={needIdx => this.props.needChanged(concept.id, needIdx)}
        />
        <div className="concept-btn-container center">
          <Button
            color={color}
            onClick={() => this.props.conceptFinished(concept.id)}
          >
            FINISH
          </Button>
        </div>
      </div>
    )
  }

  render() {
    if (this.props.loading || this.props.concepts.length === 0)
      return <GlobalLoading />

    const concept = this.props.concepts.find(
      concept => concept.id === parseInt(this.props.match.params.id)
    )

    const domain = this.props.domains.find(
      domain => domain.id === concept.domain_id
    )

    if (this.state.goBack)
      return <Redirect push to={`/domain/${concept.domain_id}`} />

    return (
      <div className="concept">
        <div className="flex concept-header">
          <div onClick={() => this.setState({ goBack: true })}>
            <Icon name="arrow-back" width="50px" height="50px" />
          </div>
          <div className="flex-column header-text">
            <h5 className="title is-5">{concept.name}</h5>
            <h6 className="subtitle is-5">{domain.name}</h6>
          </div>
          {concept.finished && (
            <div className="concept-finished-icon">
              <Icon name="check" fill="#00CED1" width="50px" height="50px" />
            </div>
          )}
        </div>
        {this.renderConcept(concept, domain.color)}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    concepts: state.surveyState.concepts,
    domains: state.surveyState.domains,
    loading: state.surveyState.loading
  }
}

export default connect(
  mapStateToProps,
  {
    ratingChanged,
    challengeChanged,
    needChanged,
    conceptFinished,
    conceptEditing
  }
)(Concept)

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom'

import Header from './Header'
import Menu from './Menu'
import Concept from './Concept'
import Button from '../Button'
import Icon from '../Icon'
import Modal from '../Modal'
import FinishedModal from './FinishedModal'

import './desktop.css'

const NoConcept = () => {
  return (
    <div className="center">
      <p>Please select a concept in the menu to the left</p>
    </div>
  )
}

class Home extends Component {
  renderConceptRoute = () => {
    if (this.props.concepts.length > 0) {
      return (
        <Switch>
          {/* This will dynamically render a concept when a menu item is clicked through route params */}
          <Route
            path="/concept/:id"
            render={props => (
              <Concept
                {...props}
                className="concept-desktop"
                domains={this.props.domains}
                concepts={this.props.concepts}
              />
            )}
          />
          <Route exact path="/" component={NoConcept} />
        </Switch>
      )
    }
    return <NoConcept />
  }
  render() {
    return (
      <div className="home-desktop">
        <Header className="nav-desktop">
          <Link to="/report">
            <Button width="180px">
              REPORT <Icon name="report" width={75} fill="#FFF" />
            </Button>
          </Link>
        </Header>
        <Router basename={this.props.uuid}>
          <>
            <Menu className="menu-desktop" domains={this.props.domains} />
            {this.renderConceptRoute()}
          </>
        </Router>
        <Modal
          show={this.props.finished}
          onClose={() =>
            document.getElementById('modal').classList.remove('is-active')
          }
        >
          <FinishedModal />
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    domains: state.surveyState.domains,
    concepts: state.surveyState.concepts,
    finished: state.surveyState.surveyFinished
  }
}

export default connect(mapStateToProps)(Home)

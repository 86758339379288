import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import api from '../api'
import { initRespondent } from '../actions'

import RadioList from './RadioList'
import GlobalLoading from './GlobalLoading'

class IntroSurvey extends Component {
  state = {
    complete: false,
    cancel: false,
    loading: true,
    error: null,
    demographics: null,
    show_custom_one: false,
    show_custom_two: false,
    show_custom_three: false,
    formData: {
      customCodeOne: '',
      customCodeTwo: '',
      customCodeThree: '',
      radios: []
    }
  }

  componentWillMount() {
    if (this.props.survey.uuid) {
      api
        .get(`/demographics?uuid=${this.props.survey.uuid}`)
        .then(res => {
          this.setState({
            show_custom_one: res.data.show_custom_one,
            show_custom_two: res.data.show_custom_two,
            show_custom_three: res.data.show_custom_three,
            demographics: res.data.demographics,
            loading: false
          })
        })
        .catch(error => {
          this.setState({ error, loading: false })
        })
    } else {
      this.setState({ cancel: true, loading: false })
    }
  }

  handleSubmit = e => {
    e.preventDefault()
    const { survey, initRespondent } = this.props
    initRespondent(this.state.formData, survey)
    this.setState({ complete: true })
  }

  handleCancel = () => {
    this.setState({ cancel: true })
  }

  handleRadioChange = (id, text) => {
    const formData = { ...this.state.formData }
    const radios = formData.radios
    const answerIdx = radios.findIndex(answer => answer.id === id)
    if (answerIdx >= 0) {
      radios[answerIdx].text = text
    } else {
      radios.push({ id, text })
    }
    formData.radios = radios
    this.setState({ formData })
  }

  render() {
    if (this.state.loading) return <GlobalLoading />
    if (this.state.cancel || !this.props.survey.uuid)
      return <Redirect to="consent" />
    if (this.state.complete) return <Redirect push to="/" />

    return (
      <section className="section">
        <div className="container">
          <h1 className="title">Demographic Info</h1>
          <form
            id="demographic-form"
            className="intro-survey-form"
            onSubmit={this.handleSubmit}
          >
            {this.state.demographics.map(question => {
              return (
                <RadioList
                  {...question}
                  key={question.id}
                  handleRadioChange={this.handleRadioChange}
                />
              )
            })}

            {this.state.show_custom_one && (
              <div className="field" style={{ marginTop: '25px' }}>
                <label className="label">
                  If Given, Enter Custom Code One Here (Optional)
                </label>
                <div className="control">
                  <input
                    className="input is-medium is-rounded"
                    type="text"
                    placeholder="Custom Code One"
                    value={this.state.formData.customCodeOne}
                    onChange={e =>
                      this.setState({
                        formData: {
                          ...this.state.formData,
                          customCodeOne: e.target.value
                        }
                      })
                    }
                  />
                </div>
              </div>
            )}
            {this.state.show_custom_two && (
              <div className="field">
                <label className="label">
                  If Given, Enter Custom Code Two Here (Optional)
                </label>
                <div className="control">
                  <input
                    className="input is-medium is-rounded"
                    type="text"
                    placeholder="Custom Code Two"
                    value={this.state.formData.customCodeTwo}
                    onChange={e =>
                      this.setState({
                        formData: {
                          ...this.state.formData,
                          customCodeTwo: e.target.value
                        }
                      })
                    }
                  />
                </div>
              </div>
            )}
            {this.state.show_custom_three && (
              <div className="field" style={{ marginBottom: '50px' }}>
                <label className="label">
                  If Given, Enter Custom Code Three Here (Optional)
                </label>
                <div className="control">
                  <input
                    className="input is-medium is-rounded"
                    type="text"
                    placeholder="Custom Code Three"
                    value={this.state.formData.customCodeThree}
                    onChange={e =>
                      this.setState({
                        formData: {
                          ...this.state.formData,
                          customCodeThree: e.target.value
                        }
                      })
                    }
                  />
                </div>
              </div>
            )}
            <div className="field is-grouped">
              <div className="control">
                <button className="button is-medium is-rounded is-primary">
                  Continue
                </button>
              </div>
              <div className="control">
                <button
                  className="button is-medium is-text"
                  onClick={this.handleCancel}
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
        {/* <footer className="footer">
          <div className="content has-text-centered">
            © {new Date().getFullYear()} Regents of the University of Minnesota.
            All rights reserved.
          </div>
        </footer> */}
      </section>
    )
  }
}

const mapStateToProps = state => {
  // Get survey info
  const survey = {
    uuid: state.surveyState.uuid,
    concepts: state.surveyState.concepts,
    domains: state.surveyState.domains,
    surveyFinished: state.surveyState.surveyFinished
  }
  return {
    survey,
    loading: state.surveyState.loading
  }
}

export default connect(mapStateToProps, { initRespondent })(IntroSurvey)

import React from 'react'

import Header from './Header'
import DomainList from './DomainList'

import './mobile.css'

const Container = () => {
  return (
    <div className="mobile-home">
      <Header subtitle="Select a Domain" iconName="report" route="/report" />
      <div className="home-container">
        <DomainList />
      </div>
    </div>
  )
}

export default Container

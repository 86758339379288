import React, { useState } from 'react'

import ReportItem from './Desktop/ReportItem'
import ReportCard from './Mobile/ReportCard'
import Icon from './Icon'

const sortTypes = {
  BEST_RATING: 'BEST_RATING',
  WORST_RATING: 'WORST_RATING'
}

const SummaryReportList = ({ summary, mobile }) => {
  const [sortType, setSortType] = useState(sortTypes.BEST_RATING)

  const compare = (a, b) => {
    if (a > b) {
      return 1
    } else if (a < b) {
      return -1
    } else {
      return 0
    }
  }

  const renderDesktopReportList = () => {
    switch (sortType) {
      case sortTypes.WORST_RATING:
        return summary
          .sort((a, b) => compare(a.rating, b.rating))
          .map(concept => {
            return <ReportItem {...concept} key={concept.id} />
          })
      case sortTypes.BEST_RATING:
        return summary
          .sort((a, b) => compare(b.rating, a.rating))
          .map(concept => {
            return <ReportItem {...concept} key={concept.id} />
          })
      default:
        return summary.map(concept => {
          return <ReportItem {...concept} key={concept.id} />
        })
    }
  }

  const renderMobileReportList = () => {
    switch (sortType) {
      case sortTypes.WORST_RATING:
        return summary
          .filter(concept => concept.finished === true)
          .sort((a, b) => compare(a.rating, b.rating))
          .map(concept => {
            return <ReportCard concept={concept} key={concept.id} />
          })
      case sortTypes.BEST_RATING:
        return summary
          .filter(concept => concept.finished === true)
          .sort((a, b) => compare(b.rating, a.rating))
          .map(concept => {
            return <ReportCard concept={concept} key={concept.id} />
          })
      default:
        return summary
          .filter(concept => concept.finished === true)
          .map(concept => {
            return <ReportCard concept={concept} key={concept.id} />
          })
    }
  }

  const renderSortIcon = () => {
    switch (sortType) {
      case sortTypes.BEST_RATING:
        return (
          <Icon
            name="arrow-dropup"
            height="25px"
            width="25px"
            style={{ marginLeft: '10px' }}
          />
        )
      case sortTypes.WORST_RATING:
        return (
          <Icon
            name="arrow-dropdown"
            height="25px"
            width="25px"
            style={{ marginLeft: '10px' }}
          />
        )
      default:
        return (
          <Icon
            name="arrow-dropup"
            height="25px"
            width="25px"
            style={{ marginLeft: '10px' }}
          />
        )
    }
  }

  const changeSortType = () => {
    if (sortType === sortTypes.BEST_RATING) {
      setSortType(sortTypes.WORST_RATING)
    } else {
      setSortType(sortTypes.BEST_RATING)
    }
  }

  return mobile ? (
    <>
      <div className="mobile-report-sort" onClick={changeSortType}>
        Sort By Rating {renderSortIcon()}
      </div>
      <div className="mobile-report-list">{renderMobileReportList()}</div>
    </>
  ) : (
    <table className="report-table">
      <thead>
        <tr>
          <th className="th-center">Star</th>
          <th>Concept</th>
          <th className="th-center" onClick={changeSortType}>
            Rating
            {renderSortIcon()}
          </th>
          <th>Challenges</th>
          <th>Needs</th>
        </tr>
      </thead>
      <tbody>{renderDesktopReportList()}</tbody>
    </table>
  )
}

export default SummaryReportList

import React from 'react'

import { ReactComponent as PrimaryLogo } from '../../assets/MSMH_Primary_Logo.svg'
import FontSizeToggle from '../FontSizeToggle'

const Header = ({ className, children, report }) => {
  return (
    <nav
      className={`level is-mobile ${className}`}
      style={{ marginBottom: '0' }}
    >
      <div className="level-left">
        <div className="level-item">
          <PrimaryLogo width={300} height={70} />
        </div>
        {!report && (
          <div className="level-item" style={{ marginLeft: '25px' }}>
            <h6 className="title is-6">Font Size:</h6>
            <FontSizeToggle />
          </div>
        )}
      </div>
      <div className="level-right">
        <div className="level-item">{children}</div>
      </div>
    </nav>
  )
}

export default Header

export const buildCategoryList = (concepts, categories) => {
  const list = {}
  concepts.forEach(concept => {
    const category = categories.find(
      category => category.id === concept.category_id
    )
    list[category.name]
      ? list[category.name].push(concept)
      : (list[category.name] = [concept])
  })
  return list
}

export const calculateDomainData = (domain_id, concepts) => {
  const needCount = [0, 0, 0, 0]
  let totalRating = 0
  const filteredConcepts = concepts.filter(
    concept => concept.domain_id === domain_id && concept.finished
  )
  filteredConcepts.forEach(concept => {
    totalRating += concept.rating
    concept.needs.forEach((need, idx) => {
      if (need.selected) {
        needCount[idx]++
      }
    })
  })
  const avgConceptRating = +(totalRating / filteredConcepts.length).toFixed(2)
  const reciprocal = +(5 - avgConceptRating).toFixed(2)
  return {
    ratingData: [
      {
        name: 'Average Concept Rating',
        value: avgConceptRating - 1,
        fill: '#23D160'
      },
      {
        name: 'Average Concept Rating Reciprocal',
        value: reciprocal,
        fill: '#FF3860'
      }
    ],
    needData: [
      {
        name: 'Check-ins',
        value: needCount[0],
        fill: '#2EBC2E'
      },
      {
        name: 'Hands-on Care',
        value: needCount[1],
        fill: '#1E90FF'
      },
      {
        name: 'Info / Guidance',
        value: needCount[2],
        fill: '#E95AE9'
      },
      {
        name: 'Care Coordination',
        value: needCount[3],
        fill: '#FFA500'
      }
    ]
  }
}

export const getNumberOfChallenges = (domain_id, concepts) => {
  const filteredConcepts = concepts.filter(
    concept => concept.domain_id === domain_id && concept.finished
  )
  return filteredConcepts.reduce((total, concept) => {
    total += concept.challenges.reduce((totalSelected, challenge) => {
      if (challenge.selected) totalSelected++
      return totalSelected
    }, 0)
    return total
  }, 0)
}

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { conceptStarred } from '../../actions'

import ReportItem from './ReportItem'
import Icon from '../Icon'
import Modal from '../Modal'

const sortTypes = {
  BEST_RATING: 'BEST_RATING',
  WORST_RATING: 'WORST_RATING'
}

class ReportList extends Component {
  state = {
    sortType: sortTypes.WORST_RATING,
    showStarInfoModal: false
  }
  compare(a, b) {
    if (a > b) {
      return 1
    } else if (a < b) {
      return -1
    } else {
      return 0
    }
  }

  renderReportList = () => {
    switch (this.state.sortType) {
      case sortTypes.WORST_RATING:
        return this.props.concepts
          .sort((a, b) => this.compare(a.rating, b.rating))
          .map(concept => {
            return (
              <ReportItem
                {...concept}
                key={concept.id}
                onStar={() => this.props.conceptStarred(concept.id)}
              />
            )
          })
      case sortTypes.BEST_RATING:
        return this.props.concepts
          .sort((a, b) => this.compare(b.rating, a.rating))
          .map(concept => {
            return (
              <ReportItem
                {...concept}
                key={concept.id}
                onStar={() => this.props.conceptStarred(concept.id)}
              />
            )
          })
      default:
        return this.props.concepts.map(concept => {
          return (
            <ReportItem
              {...concept}
              key={concept.id}
              onStar={() => this.props.conceptStarred(concept.id)}
            />
          )
        })
    }
  }

  renderSortIcon = () => {
    switch (this.state.sortType) {
      case sortTypes.BEST_RATING:
        return (
          <Icon
            name="arrow-dropup"
            height="25px"
            width="25px"
            style={{ marginLeft: '10px' }}
          />
        )
      case sortTypes.WORST_RATING:
        return (
          <Icon
            name="arrow-dropdown"
            height="25px"
            width="25px"
            style={{ marginLeft: '10px' }}
          />
        )
      default:
        return (
          <Icon
            name="arrow-dropup"
            height="25px"
            width="25px"
            style={{ marginLeft: '10px' }}
          />
        )
    }
  }

  changeSortType = () => {
    if (this.state.sortType === sortTypes.BEST_RATING) {
      this.setState({ sortType: sortTypes.WORST_RATING })
    } else {
      this.setState({ sortType: sortTypes.BEST_RATING })
    }
  }

  render() {
    return (
      <table className="report-table">
        <thead>
          <tr>
            <th
              className="th-center"
              onClick={() => this.setState({ showStarInfoModal: true })}
            >
              Star
              <Icon
                name="help-circle"
                height="25px"
                width="25px"
                fill="#0CA7D3"
                style={{ marginLeft: '10px' }}
              />
            </th>
            <th>Concept</th>
            <th className="th-center" onClick={this.changeSortType}>
              Rating
              {this.renderSortIcon()}
            </th>
            <th>Challenges</th>
            <th>Needs</th>
          </tr>
        </thead>
        <tbody>{this.renderReportList()}</tbody>
        <Modal
          show={this.state.showStarInfoModal}
          onClose={() => this.setState({ showStarInfoModal: false })}
        >
          <div className="star-modal">
            <h1 className="title">Starring Concepts</h1>
            <h2 className="subtitle">
              Star if you want to talk about this with your health care team.
            </h2>
          </div>
        </Modal>
      </table>
    )
  }
}

const mapStateToProps = state => {
  return {
    concepts: state.surveyState.concepts.filter(
      concept => concept.finished === true
    )
  }
}

export default connect(
  mapStateToProps,
  { conceptStarred }
)(ReportList)

import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import SummaryReportList from './SummaryReportList'
import DesktopHeader from './Desktop/Header'
import MobileHeader from './Mobile/Header'
import TwoLevelPie from './TwoLevelPie'
import SummaryParagraph from './SummaryParagraph'

import { calculateDomainData, getNumberOfChallenges } from '../helpers'

const Summary = ({ summary }) => {
  const [width, setWidth] = useState(window.innerWidth)
  const [toDataParagraph, setToDataParagraph] = useState(false)

  useEffect(() => {
    window.addEventListener('resize', () => setWidth(window.innerWidth))

    return () => {
      window.removeEventListener('resize', () => setWidth(window.innerWidth))
    }
  })

  if (summary.length <= 0) return <Redirect to="/summaries" />
  if (toDataParagraph)
    return <SummaryParagraph onBack={() => setToDataParagraph(false)} />

  return width <= 650 ? (
    <div className="mobile-report">
      <MobileHeader subtitle="Report Page" iconName="survey" />
      <div className="report-content">
        <h1 className="title center">Report on {new Date().toDateString()}</h1>
        Access Code: {summary.access_code}
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            marginBottom: '10px'
          }}
        >
          <button
            className="report-print-btn"
            onClick={() => setToDataParagraph(true)}
          >
            Print or save as pdf
          </button>
        </div>
        <div className="report-legend">
          <span className="legend-title">Legend</span>

          <div className="legend-section">
            <div className="legend-item">
              <div className="legend-icon legend-icon--green" />
              <span>Average Concept Rating</span>
            </div>
          </div>

          <div className="legend-section">
            <div className="legend-item">
              <div className="legend-icon legend-icon--alt-green" />
              <span>Check-ins</span>
            </div>
            <div className="legend-item">
              <div className="legend-icon legend-icon--blue" />
              <span>Hands-on Care</span>
            </div>
            <div className="legend-item">
              <div className="legend-icon legend-icon--purple" />
              <span>Info / Guidance</span>
            </div>
            <div className="legend-item">
              <div className="legend-icon legend-icon--orange" />
              <span>Care Coordination</span>
            </div>
          </div>
        </div>
        <div className="columns is-mobile is-multiline is-centered">
          <div className="column">
            <TwoLevelPie
              title="My Living"
              data={calculateDomainData(1, summary.data)}
              challNum={getNumberOfChallenges(1, summary.data)}
            />
          </div>
          <div className="column">
            <TwoLevelPie
              title="My Mind & Networks"
              data={calculateDomainData(2, summary.data)}
              challNum={getNumberOfChallenges(2, summary.data)}
            />
          </div>
          <div className="column">
            <TwoLevelPie
              title="My Body"
              data={calculateDomainData(3, summary.data)}
              challNum={getNumberOfChallenges(3, summary.data)}
            />
          </div>
          <div className="column">
            <TwoLevelPie
              title="My Self-Care"
              data={calculateDomainData(4, summary.data)}
              challNum={getNumberOfChallenges(4, summary.data)}
            />
          </div>
        </div>
        <SummaryReportList mobile summary={summary.data} />
      </div>
    </div>
  ) : (
    <div className="report-desktop">
      <DesktopHeader className="nav-desktop" report>
        Access Code: {summary.access_code}
      </DesktopHeader>
      <div className="report">
        <section className="section">
          <h1 className="title center">
            Report on {new Date(summary.timestamp).toDateString()}
          </h1>

          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              marginBottom: '10px'
            }}
          >
            <button
              className="report-print-btn"
              onClick={() => setToDataParagraph(true)}
            >
              Print or save as pdf
            </button>
          </div>

          <div className="report-legend">
            <span className="legend-title">Legend</span>

            <div className="legend-section">
              <div className="legend-item">
                <div className="legend-icon legend-icon--green" />
                <span>Average Concept Rating</span>
              </div>
            </div>

            <div className="legend-section">
              <div className="legend-item">
                <div className="legend-icon legend-icon--alt-green" />
                <span>Check-ins</span>
              </div>
              <div className="legend-item">
                <div className="legend-icon legend-icon--blue" />
                <span>Hands-on Care</span>
              </div>
              <div className="legend-item">
                <div className="legend-icon legend-icon--purple" />
                <span>Info / Guidance</span>
              </div>
              <div className="legend-item">
                <div className="legend-icon legend-icon--orange" />
                <span>Care Coordination</span>
              </div>
            </div>
          </div>

          <div className="columns is-mobile is-multiline is-centered">
            <div className="column">
              <TwoLevelPie
                title="My Living"
                data={calculateDomainData(1, summary.data)}
                challNum={getNumberOfChallenges(1, summary.data)}
              />
            </div>
            <div className="column">
              <TwoLevelPie
                title="My Mind & Networks"
                data={calculateDomainData(2, summary.data)}
                challNum={getNumberOfChallenges(2, summary.data)}
              />
            </div>
            <div className="column">
              <TwoLevelPie
                title="My Body"
                data={calculateDomainData(3, summary.data)}
                challNum={getNumberOfChallenges(3, summary.data)}
              />
            </div>
            <div className="column">
              <TwoLevelPie
                title="My Self-Care"
                data={calculateDomainData(4, summary.data)}
                challNum={getNumberOfChallenges(4, summary.data)}
              />
            </div>
          </div>
        </section>
        <section className="section">
          <SummaryReportList summary={summary.data} />
        </section>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return { summary: state.summaryState.summary }
}

export default connect(mapStateToProps)(Summary)

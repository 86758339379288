import React, { Component } from 'react'
import { connect } from 'react-redux'
import { clearRespondent, fetchSurvey } from '../actions'
import { Link } from 'react-router-dom'
import GlobalLoading from './GlobalLoading'

class Consent extends Component {
  state = {
    isConsented: false
  }

  componentWillMount() {
    if (localStorage.getItem('respondent') && localStorage.getItem('survey')) {
      this.props.clearRespondent()
      this.props.fetchSurvey()
    }
  }

  renderButton = () => {
    return this.state.isConsented ? (
      <Link to="/intro-survey">
        <button className="button is-rounded is-primary is-medium">
          Continue
        </button>
      </Link>
    ) : (
      <button className="button is-rounded is-primary is-medium" disabled>
        Continue
      </button>
    )
  }

  render() {
    if (this.props.loading) return <GlobalLoading />
    return (
      <section className="section" style={{ paddingBottom: '170px' }}>
        <div className="container">
          {this.props.error && (
            <article className="message is-danger">
              <div className="message-header">
                <p>Error</p>
              </div>
              <div className="message-body">
                There has been an error with this application, please check your
                network connection
              </div>
            </article>
          )}

          <h1 className="title">My Strengths + My Health</h1>
          <p className="subtitle">
            It is your choice to take part in this health report. It is your
            choice to answer any of the questions. Your answers are kept safe.
            Your unidentified data can be used for research studies. Your ID
            will not be shown to the public.
          </p>
          <label className="consent-checkbox">
            <input
              type="checkbox"
              onChange={e => this.setState({ isConsented: e.target.checked })}
            />
            I have read the above information. By continuing, I am consenting to
            participate in the study.
          </label>
          <div className="consent-btn" disabled={this.props.error}>
            {this.renderButton()}
          </div>
        </div>
        <footer className="footer">
          <div className="content has-text-centered">
            © {new Date().getFullYear()} Regents of the University of Minnesota.
            All rights reserved.
          </div>
        </footer>
      </section>
    )
  }
}

const mapStateToProps = state => {
  return {
    loading: state.surveyState.loading,
    error: state.surveyState.erorr,
    consented: state.respondentState.consented
  }
}

export default connect(
  mapStateToProps,
  { clearRespondent, fetchSurvey }
)(Consent)

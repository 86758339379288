import React from 'react'
import { connect } from 'react-redux'

import { fontSizeChanged } from '../actions'

const FontSizeToggle = ({ fontSizeChanged, fontSize }) => {
  return (
    <div className="font-size-toggle">
      <div
        className={`regular-size${fontSize === 'regular' ? ' active' : ''}`}
        onClick={() => fontSizeChanged('regular')}
      >
        A
      </div>
      <div
        className={`large-size${fontSize === 'large' ? ' active' : ''}`}
        onClick={() => fontSizeChanged('large')}
      >
        A
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    fontSize: state.surveyState.fontSize
  }
}

export default connect(
  mapStateToProps,
  { fontSizeChanged }
)(FontSizeToggle)

import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'

import LandingPage from './LandingPage'
import SummaryCode from './SummaryCode'
import Summary from './Summary'

const NoUuid = () => {
  return (
    <Router>
      <Route exact path="/" component={LandingPage} />
      <Route path="/summaries" component={SummaryCode} />
      <Route path="/summary" component={Summary} />
    </Router>
  )
}

export default NoUuid

import React from 'react'

const DomainCard = ({ color, title, total = 12, finished = 0, onClick }) => {
  return (
    <div
      className="domain-card"
      style={{ backgroundColor: color }}
      onClick={onClick}
    >
      <h4 className="title is-4 domain-card-header">{title}</h4>
      <h6 className="subtitle domain-card-subheader">
        {finished} / {total} Surveys
      </h6>
    </div>
  )
}

export default DomainCard

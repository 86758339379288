import React from 'react'

import Icon from '../Icon'

const ConceptCard = ({ name, iconName, color, finished, onClick }) => {
  return (
    <div className="concept-card" onClick={onClick}>
      {finished && (
        <div className="concept-card-finished-icon">
          <Icon name="check" fill="#00CED1" width="50px" height="50px" />
        </div>
      )}
      <div className="concept-card-icon">
        <img
          src={require(`../../assets/survey_icons/${iconName}`)}
          alt={name}
        />
      </div>
      <div className="concept-card-footer" style={{ backgroundColor: color }}>
        <span style={{ fontSize: '20px' }}>{name}</span>
        {!finished && (
          <Icon width="35" height="35" name="arrow-forward" fill="#FFF" />
        )}
      </div>
    </div>
  )
}

export default ConceptCard

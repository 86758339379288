import React from 'react'

import Header from './Header'
import Button from '../Button'
import Icon from '../Icon'

const renderMenuSkeleton = () => {
  return [1, 2, 3, 4].map(i => {
    return (
      <React.Fragment key={i}>
        <div className="menu-label">
          <div className="skeleton header-skel" />
        </div>
        <ul className="menu-list">
          <li>
            <div className="skeleton txt-skel" />
          </li>
          <li>
            <div className="skeleton txt-skel" />
          </li>
          <li>
            <div className="skeleton txt-skel" />
          </li>
        </ul>
      </React.Fragment>
    )
  })
}

const renderConceptSkeleton = () => {
  return (
    <>
      <div className="skeleton section-skel" />
      <div className="skeleton header-skel" />
      <br />
      <div className="skeleton header-skel" />
      <br />
      <br />
      <div style={{ display: 'flex' }}>
        <div className="skeleton checkbox-skel" />
        <div className="skeleton q-skel" />
      </div>
      <div style={{ display: 'flex' }}>
        <div className="skeleton checkbox-skel" />
        <div className="skeleton q-skel" />
      </div>
      <div style={{ display: 'flex' }}>
        <div className="skeleton checkbox-skel" />
        <div className="skeleton q-skel" />
      </div>
    </>
  )
}

const renderNavSkeleton = () => {
  return (
    <Header>
      <Button width="180px">
        REPORT <Icon name="report" width={75} fill="#FFF" />
      </Button>
    </Header>
  )
}

const HomeSkeleton = () => {
  return (
    <div className="home-desktop">
      <div className="nav-desktop">{renderNavSkeleton()}</div>
      <div className="menu-desktop">
        <aside className="menu">{renderMenuSkeleton()}</aside>
      </div>
      <div className="concept-desktop">{renderConceptSkeleton()}</div>
    </div>
  )
}

export default HomeSkeleton

import React from 'react'
import { connect } from 'react-redux'

const Checkbox = ({ isChecked, id, onChange, color }) => {
  return (
    <div
      className={`checkbox${isChecked ? ' is-checked' : ''}`}
      style={{ backgroundColor: color }}
      onClick={onChange}
      data-id={id}
    />
  )
}

const splitQuestions = challenges => {
  // Split the challenges array into two different arrays
  // I want to render these in two different columns
  let middleIdx = Math.ceil(challenges.length / 2)
  const leftArr = challenges.slice(0, middleIdx)
  const rightArr = challenges.slice(middleIdx)
  return [leftArr, rightArr]
}

const renderDesktopQuestions = (challenges, fontSize, onChange) => {
  if (challenges.length > 3 && fontSize === 'regular') {
    const challengesArray = splitQuestions(challenges)
    return (
      <div className="challenge-list-desktop columns">
        <div className="column">
          {challengesArray[0].map(item => {
            return (
              <div className="challenge" key={item.id}>
                <Checkbox
                  id={item.id}
                  isChecked={item.selected}
                  onChange={onChange}
                />
                <div className="challenge-text">{item.text}</div>
              </div>
            )
          })}
        </div>
        <div className="column">
          {challengesArray[1].map(item => {
            return (
              <div className="challenge" key={item.id}>
                <Checkbox
                  id={item.id}
                  isChecked={item.selected}
                  onChange={onChange}
                />
                <div className="challenge-text">{item.text}</div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  return (
    <div className="challenge-list-desktop large-font">
      {challenges.map(item => {
        return (
          <div className="challenge" key={item.id}>
            <Checkbox
              id={item.id}
              isChecked={item.selected}
              onChange={onChange}
            />
            <div className="challenge-text">{item.text}</div>
          </div>
        )
      })}
    </div>
  )
}

const CheckboxList = ({ challenges, mobile, fontSize, onChange }) => {
  return mobile ? (
    <div className="challenge-list-mobile">
      {challenges.map(item => {
        return (
          <div className="challenge" key={item.id}>
            <div className="challenge-text">{item.text}</div>
            <Checkbox
              id={item.id}
              isChecked={item.selected}
              onChange={onChange}
            />
          </div>
        )
      })}
    </div>
  ) : (
    renderDesktopQuestions(challenges, fontSize, onChange)
  )
}

const mapStateToProps = state => {
  return {
    fontSize: state.surveyState.fontSize
  }
}

export default connect(mapStateToProps)(CheckboxList)

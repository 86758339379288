import React from 'react'
import { connect } from 'react-redux'
import { restart } from '../actions'

import Button from './Button'
import GlobalLoading from './GlobalLoading'

const Code = ({ code, loading, restart }) => {
  if (loading) return <GlobalLoading />
  return (
    <div className="code-page center">
      <div className="code-container center">
        <h1 className="title">You are done!</h1>
        <h2 className="subtitle">
          If you would like to go back and view your results after you close
          this page, copy this code below and enter it at
          <a
            href="https://assessment.mystrengths.umn.edu/summaries"
            target="_blank"
            rel="noopener noreferrer"
          >
            {' '}
            assessment.mystrengths.umn.edu/summaries
          </a>
        </h2>
        <div className="code">
          <h2 className="subtitle code-description">Code:</h2>
          <h1 className="title code-text">{code}</h1>
        </div>
        <Button
          onClick={() => {
            restart()
            window.location.reload()
          }}
        >
          START OVER
        </Button>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    code: state.respondentState.access_code,
    uuid: state.surveyState.uuid,
    loading: state.respondentState.loading
  }
}

export default connect(
  mapStateToProps,
  { restart }
)(Code)

import React from 'react'
import ReactDOM from 'react-dom'

const Modal = ({ show, onClose, children }) => {
  return show
    ? ReactDOM.createPortal(
        <div id="modal" className="modal  is-active">
          <div className="modal-background" />
          <div className="modal-content">{children}</div>
          <button
            className="modal-close is-large"
            aria-label="close"
            onClick={onClose}
          />
        </div>,
        document.querySelector('#modal-root')
      )
    : null
}

export default Modal

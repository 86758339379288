import React, { useState } from 'react'

import Icon from '../Icon'
import Modal from '../Modal'

const colorMap = [
  '#C4C4C4',
  '#FF3860',
  '#FF3860',
  '#FED11A',
  '#23D160',
  '#23D160'
]

const NeedModal = ({ needs }) => {
  return (
    <div className="needs-modal">
      <h4 className="title is-4">Needs</h4>
      <div
        className="report-needs"
        style={{ backgroundColor: needs[0].selected ? '#2EBC2E' : 'white' }}
      >
        Check-ins
      </div>
      <div
        className="report-needs"
        style={{ backgroundColor: needs[1].selected ? '#1E90FF' : 'white' }}
      >
        Hands-on Care
      </div>
      <div
        className="report-needs"
        style={{ backgroundColor: needs[2].selected ? '#E95AE9' : 'white' }}
      >
        Info / Guidance
      </div>
      <div
        className="report-needs"
        style={{ backgroundColor: needs[3].selected ? '#FFA500' : 'white' }}
      >
        Care Coordination
      </div>
    </div>
  )
}

const ChallengeModal = ({ challenges }) => {
  return (
    <div className="challenges-modal">
      <h4 className="title is-4">Challenges</h4>
      {challenges
        .filter(c => c.selected === true)
        .map(challenge => {
          return <div key={challenge.id}>- {challenge.text}</div>
        })}
    </div>
  )
}

const ReportCard = ({ concept, onStar }) => {
  const [showNeedModal, handleNeedClick] = useState(false)
  const [showChallengeModal, handleChallengeClick] = useState(false)

  const ratingMap = ['No Rating', ...concept.rating_text.split('|')]
  return (
    <div className="report-card">
      <div className="card-title">
        <div className="title-text">
          <h5 className="title is-5" style={{ marginBottom: 0 }}>
            {concept.name}
          </h5>
          <div
            className="card-rating"
            style={{
              backgroundColor: concept.rating
                ? colorMap[concept.rating]
                : colorMap[0]
            }}
          >
            {concept.rating ? ratingMap[concept.rating] : ratingMap[0]}
          </div>
        </div>
        <div className="report-star" onClick={onStar}>
          {concept.starred ? (
            <Icon
              name="star"
              height="35px"
              width="35px"
              fill="rgb(254, 209, 26)"
            />
          ) : (
            <Icon name="star-outline" height="35px" width="35px" />
          )}
        </div>
      </div>
      <div className="card-btns">
        <div className="card-btn b-right" onClick={() => handleNeedClick(true)}>
          <h6 className="subtitle is-6">Needs</h6>
        </div>
        <div
          className="card-btn b-left"
          onClick={() => handleChallengeClick(true)}
        >
          <h6 className="subtitle is-6">Challenges</h6>
        </div>
      </div>

      <Modal show={showNeedModal} onClose={() => handleNeedClick(false)}>
        <NeedModal needs={concept.needs} />
      </Modal>
      <Modal
        show={showChallengeModal}
        onClose={() => handleChallengeClick(false)}
      >
        <ChallengeModal challenges={concept.challenges} />
      </Modal>
    </div>
  )
}

export default ReportCard

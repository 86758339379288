import React from 'react'
import Icon from './Icon'

const NeedSelection = ({ needs, onChange, name }) => {
  return (
    <div className="needs-selection-container">
      <h5 className="title is-5">My {name} Needs</h5>
      <div className="needs-icons">
        <div
          className={`need-icon${needs[0].selected ? ' is-selected' : ''}`}
          onClick={() => onChange(0)}
        >
          <Icon name="check-circle" fill="#2EBC2E" width={50} height={65} />
          <p>Check-ins</p>
        </div>
        <div
          className={`need-icon${needs[1].selected ? ' is-selected' : ''}`}
          onClick={() => onChange(1)}
        >
          <Icon name="add-circle" fill="#1E90FF" width={50} height={65} />
          <p>Hands-on Care</p>
        </div>
        <div
          className={`need-icon${needs[2].selected ? ' is-selected' : ''}`}
          onClick={() => onChange(2)}
        >
          <Icon name="info-circle" fill="#E95AE9" width={50} height={65} />
          <p>Info / Guidance</p>
        </div>
        <div
          className={`need-icon${needs[3].selected ? ' is-selected' : ''}`}
          onClick={() => onChange(3)}
        >
          <Icon
            name="coordination-circle"
            fill="#FFA500"
            width={50}
            height={65}
            viewBox="0 0 50 50"
          />
          <p>Care Coordination</p>
        </div>
      </div>
    </div>
  )
}

export default NeedSelection

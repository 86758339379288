import React, { Component } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

import HomeMobile from './Mobile/Home'
import HomeDesktop from './Desktop/Home'
import HomeSkeleton from './Desktop/HomeSkeleton'
import ReportDesktop from './Desktop/Report'
import ReportMobile from './Mobile/Report'
import DataParagraph from './DataParagraph'
import ConceptListMobile from './Mobile/ConceptList'
import ConceptMobile from './Mobile/Concept'
import ReportSkeleton from './Desktop/ReportSkeleton'
import Code from './Code'

class Container extends Component {
  state = {
    width: window.innerWidth
  }

  componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange)
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth })
  }

  renderMobileRoutes() {
    return (
      <Switch>
        <Route path="/code" component={Code} />
        <Route path="/report" component={ReportMobile} />
        <Route path="/domain/:id" component={ConceptListMobile} />
        <Route path="/concept/:id" component={ConceptMobile} />
        <Route path="/" exact component={HomeMobile} />
      </Switch>
    )
  }

  renderDesktopRoutes() {
    if (this.props.loading) {
      return (
        <Switch>
          <Route path="/report" component={ReportSkeleton} />
          <Route path="/" exact component={HomeSkeleton} />
        </Switch>
      )
    }

    return (
      <Switch>
        <Route path="/code" component={Code} />
        <Route path="/report" component={ReportDesktop} />
        <Route path="/data-paragraph" component={DataParagraph} />
        <Route
          path="/"
          render={props => <HomeDesktop {...props} uuid={this.props.uuid} />}
        />
      </Switch>
    )
  }

  render() {
    if (!localStorage.getItem('respondent') && !localStorage.getItem('survey'))
      return <Redirect to="/consent" />
    const isMobile = this.state.width <= 650
    return isMobile ? this.renderMobileRoutes() : this.renderDesktopRoutes()
  }
}

const mapStateToProps = state => {
  return {
    loading: state.surveyState.loading
  }
}

export default connect(mapStateToProps)(Container)

import React from 'react'

// const getViewBox = name => {
//   switch(name) {
//     default:
//       return '0 0 512 512'
//   }
// }

const getPath = (name, props) => {
  switch (name) {
    case 'survey':
      return (
        <>
          <path
            {...props}
            d="M464 64H192c-8.8 0-16 7.7-16 16.5V112H74c-23.1 0-42 18.9-42 42v207.5c0 47.6 39 86.5 86 86.5h279.7c45.1 0 82.3-36.9 82.3-82V80c0-8.8-7.2-16-16-16zm-288 80v192h-42V163.2c0-6.8-.8-13.3-3.3-19.2H176zm-17 255.4C148 410 133.2 416 118.5 416c-14.5 0-28.1-5.7-38.5-16-10.3-10.3-16-24-16-38.5V163.2c0-10.6 8.4-19.2 19-19.2s19 8.6 19 19.2V352c0 8.8 7.2 16 16 16h57.5c-1.5 11.6-7.2 22.6-16.5 31.4zM448 366c0 13.3-5.4 25.8-14.9 35.3-9.5 9.5-22.2 14.7-35.4 14.7H187.3c12.8-14.9 20.7-33.9 20.7-54.5V97h240v269z"
          />
          <path
            {...props}
            d="M248 136h160v56H248zM248 224h160v32H248zM248 288h160v32H248zM408 352H248s0 32-8 32h148.7c19.3 0 19.3-21 19.3-32z"
          />
        </>
      )
    case 'report':
      return (
        <>
          <path
            {...props}
            d="M256 48C141.1 48 48 141.1 48 256s93.1 208 208 208 208-93.1 208-208S370.9 48 256 48zM76 256c0-48.1 18.7-93.3 52.7-127.3S207.9 76 256 76c48.1 0 93.3 18.7 127.3 52.7 32.2 32.2 50.7 74.5 52.6 119.7-8.8-10.3-24.2-24-43.8-24-27.5 0-41.7 25.7-51 42.7-1.4 2.5-2.7 4.9-3.9 7-11.4 19.2-27.3 30-42.5 28.9-13.4-.9-24.8-11.2-32.2-28.8-9.2-22.1-29.1-45.8-52.9-49.2-11.3-1.6-28.1.8-44.7 21.4-3.2 4-6.9 9.4-11.1 15.6-10.4 15.5-26.2 38.8-38.1 40.8-17.3 2.8-30.9-7.5-36.4-12.3-2.2-11.2-3.3-22.8-3.3-34.5z"
          />
        </>
      )
    case 'sort':
      return (
        <path
          {...props}
          d="M96 348h320v36H96zM96 128h320v36H96zM96 200.7h320v35.6H96zM96 275.8h320v35.6H96z"
        />
      )
    case 'add':
      return (
        <path
          {...props}
          d="M416 277.333H277.333V416h-42.666V277.333H96v-42.666h138.667V96h42.666v138.667H416v42.666z"
        />
      )
    case 'check':
      return (
        <path
          {...props}
          d="M186.301 339.893L96 249.461l-32 30.507L186.301 402 448 140.506 416 110z"
        />
      )
    case 'info-circle':
      return (
        <path
          {...props}
          d="M256 48C141.2 48 48 141.2 48 256s93.2 208 208 208 208-93.2 208-208S370.8 48 256 48zm21 312h-42V235h42v125zm0-166h-42v-42h42v42z"
        />
      )
    case 'add-circle':
      return (
        <path
          {...props}
          d="M256 48C141.125 48 48 141.125 48 256s93.125 208 208 208 208-93.125 208-208S370.875 48 256 48zm107 229h-86v86h-42v-86h-86v-42h86v-86h42v86h86v42z"
        />
      )
    case 'check-circle':
      return (
        <path
          {...props}
          d="M256 48C141.6 48 48 141.6 48 256s93.6 208 208 208 208-93.6 208-208S370.4 48 256 48zm-42.7 318.9L106.7 260.3l29.9-29.9 76.8 76.8 162.1-162.1 29.9 29.9-192.1 191.9z"
        />
      )
    case 'coordination-circle':
      return (
        <>
          <circle cx="25.31" cy="25.31" r="20.31" fill={props.fill} />
          <circle cx="15" cy="26" r="5" fill="#fff" />
          <circle cx="30" cy="34" r="5" fill="#fff" />
          <circle cx="37" cy="19" r="5" fill="#fff" />
          <path
            {...props}
            stroke="#fff"
            strokeWidth="3"
            d="M12.061 29.061l-6 6M39.591 19.621l7 3M27.282 32.317l-11-6M31.658 30.329l5-10"
          />
        </>
      )
    case 'heart':
      return (
        <path
          {...props}
          d="M256 448l-30.164-27.211C118.718 322.442 48 258.61 48 179.095 48 114.221 97.918 64 162.4 64c36.399 0 70.717 16.742 93.6 43.947C278.882 80.742 313.199 64 349.6 64 414.082 64 464 114.221 464 179.095c0 79.516-70.719 143.348-177.836 241.694L256 448z"
        />
      )
    case 'arrow-back':
      return (
        <path
          {...props}
          d="M427 234.625H167.296l119.702-119.702L256 85 85 256l171 171 29.922-29.924-118.626-119.701H427v-42.75z"
        />
      )
    case 'arrow-forward':
      return (
        <path
          {...props}
          d="M85 277.375h259.704L225.002 397.077 256 427l171-171L256 85l-29.922 29.924 118.626 119.701H85v42.75z"
        />
      )
    case 'star-outline':
      return (
        <path
          {...props}
          d="M458 210.409l-145.267-12.476L256 64l-56.743 133.934L54 210.409l110.192 95.524L131.161 448 256 372.686 380.83 448l-33.021-142.066L458 210.409zM272.531 345.286L256 335.312l-16.53 9.973-59.988 36.191 15.879-68.296 4.369-18.79-14.577-12.637-52.994-45.939 69.836-5.998 19.206-1.65 7.521-17.75 27.276-64.381 27.27 64.379 7.52 17.751 19.208 1.65 69.846 5.998-52.993 45.939-14.576 12.636 4.367 18.788 15.875 68.299-59.984-36.189z"
        />
      )
    case 'star':
      return (
        <path
          {...props}
          d="M256 372.686L380.83 448l-33.021-142.066L458 210.409l-145.267-12.475L256 64l-56.743 133.934L54 210.409l110.192 95.525L131.161 448z"
        />
      )
    case 'help-circle':
      return (
        <path
          {...props}
          d="M256 48C141.2 48 48 141.2 48 256s93.2 208 208 208 208-93.2 208-208S370.8 48 256 48zm21 333h-42v-42h42v42zm-.2-63h-41.6c0-67 62.4-62.2 62.4-103.8 0-22.9-18.7-41.7-41.6-41.7S214.4 192 214.4 214h-41.6c0-46 37.2-83 83.2-83s83.2 37.1 83.2 83.1c0 52-62.4 57.9-62.4 103.9z"
        />
      )
    case 'arrow-dropdown':
      return <path {...props} d="M128 192l128 128 128-128z" />
    case 'arrow-dropup':
      return <path {...props} d="M128 320l128-128 128 128z" />
    default:
      return <path />
  }
}

const Icon = ({
  name = '',
  style = {},
  fill = '#000',
  viewBox = '0 0 512 512',
  width = '100%',
  className = '',
  height = '100%',
  onClick,
  ...props
}) => {
  return (
    <svg
      {...props}
      width={width}
      style={style}
      height={height}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      {getPath(name, { fill, onClick })}
    </svg>
  )
}

export default Icon

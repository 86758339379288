export const INIT_RESPONDENT = 'INIT_RESPONDENT'
export const CLEAR_RESPONDENT = 'CLEAR_RESPONDENT'
export const FETCH_FROM_LOCAL = 'FETCH_FROM_LOCAL'
export const FETCH_FROM_LOCAL_SUCCESS = 'FETCH_FROM_LOCAL_SUCCESS'
export const FETCH_FROM_LOCAL_FAILURE = 'FETCH_FROM_LOCAL_FAILURE'
export const FETCH_SURVEY = 'FETCH_SURVEY'
export const FETCH_SURVEY_SUCCESS = 'FETCH_SURVEY_SUCCESS'
export const FETCH_SURVEY_FAILURE = 'FETCH_SURVEY_FAILURE'
export const POST_CONCEPTS = 'POST_CONCEPTS'
export const POST_CONCEPTS_SUCCESS = 'POST_CONCEPTS_SUCCESS'
export const POST_CONCEPTS_FAILURE = 'POST_CONCEPTS_FAILURE'
export const FONT_SIZE_CHANGED = 'FONT_SIZE_CHANGED'
export const CHALLENGE_CHANGED = 'CHALLENGE_CHANGED'
export const NEED_CHANGED = 'NEED_CHANGED'
export const RATING_CHANGED = 'RATING_CHANGED'
export const CONCEPT_FINISHED = 'CONCEPT_FINISHED'
export const CONCEPT_EDITING = 'CONCEPT_EDITING'
export const CONCEPT_STARRED = 'CONCEPT_STARRED'
export const SUBMIT = 'SUBMIT'
export const SUBMIT_SUCCESS = 'SUBMIT_SUCCESS'
export const SUBMIT_FAILURE = 'SUBMIT_FAILURE'
export const RESTART = 'RESTART'
export const FETCH_SUMMARY = 'FETCH_SUMMARY'
export const FETCH_SUMMARY_SUCCESS = 'FETCH_SUMMARY_SUCCESS'
export const FETCH_SUMMARY_FAILURE = 'FETCH_SUMMARY_FAILURE'

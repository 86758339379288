import React from 'react'

import Icon from '../Icon'
import RatingSlider from '../RatingSlider'

const ChallengesList = ({ challenges }) =>
  challenges
    .filter(challenge => challenge.selected === true)
    .map(challenge => {
      return <li key={challenge.id}>- {challenge.text}</li>
    })

const ReportItem = ({
  name,
  needs,
  rating,
  rating_text,
  starred,
  onStar,
  challenges
}) => {
  return (
    <>
      <tr className="report-row">
        <td>
          {starred ? (
            <div className="report-star" onClick={onStar}>
              <Icon
                name="star"
                height="35px"
                width="35px"
                fill="rgb(254, 209, 26)"
              />
            </div>
          ) : (
            <div className="report-star" onClick={onStar}>
              <Icon name="star-outline" height="35px" width="35px" />
            </div>
          )}
        </td>
        <td>{name}</td>
        <td>
          <RatingSlider ratingText={rating_text} rating={rating} immutable />
        </td>
        <td>
          <ul>
            <ChallengesList challenges={challenges} />
          </ul>
        </td>
        <td>
          <div
            className="report-needs"
            style={{ backgroundColor: needs[0].selected ? '#2EBC2E' : 'white' }}
          >
            Check-ins
          </div>
          <div
            className="report-needs"
            style={{ backgroundColor: needs[1].selected ? '#1E90FF' : 'white' }}
          >
            Hands-on Care
          </div>
          <div
            className="report-needs"
            style={{ backgroundColor: needs[2].selected ? '#E95AE9' : 'white' }}
          >
            Info / Guidance
          </div>
          <div
            className="report-needs"
            style={{ backgroundColor: needs[3].selected ? '#FFA500' : 'white' }}
          >
            Care Coordination
          </div>
        </td>
      </tr>
    </>
  )
}

export default ReportItem

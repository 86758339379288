import React from 'react'
import { connect } from 'react-redux'
import {
  ratingChanged,
  challengeChanged,
  needChanged,
  conceptFinished,
  conceptEditing
} from '../../actions'

import CheckboxList from '../CheckboxList'
import NeedSelection from '../NeedSelection'
import Button from '../Button'
import RatingSlider from '../RatingSlider'

const FinishedConcept = ({ className, concept, color, onClick }) => {
  return (
    <div className={className}>
      <section
        className="hero is-bold concept-header-desktop"
        style={{ backgroundColor: color }}
      >
        <div className="hero-body">
          <div className="center horizontal">
            <div className="concept-titles">
              <h3 className="title">{concept.name}</h3>
            </div>
            <div className="concept-icon">
              <img
                style={{ height: '75px', width: '75px' }}
                src={require(`../../assets/survey_icons/${concept.icon_name}`)}
                alt={concept.name}
                height="75"
                width="75"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="section concept-challenges">
        <h5 className="title is-5">Thank you for completing this concept!</h5>
        <h5 className="subtitle">Choose another concept or edit this one...</h5>
      </section>
      <div className="concept-btn-container center">
        {renderBtn(concept, color, onClick)}
      </div>
    </div>
  )
}

const renderBtn = (concept, color, onClick, nextId = 1) => {
  if (concept.finished) {
    return (
      <Button color={color} onClick={() => onClick(concept.id)}>
        EDIT
      </Button>
    )
  } else {
    return (
      <Button color={color} onClick={() => onClick(concept.id)}>
        FINISH
      </Button>
    )
  }
}

const Concept = ({
  className,
  concepts,
  domains,
  match,
  ratingChanged,
  challengeChanged,
  needChanged,
  conceptFinished,
  conceptEditing,
  nextConceptId
}) => {
  // Get concept from the list of concepts from route params
  const concept = concepts.find(
    concept => concept.id.toString() === match.params.id
  )

  const color = domains.find(domain => domain.id === concept.domain_id).color

  if (concept.finished) {
    return (
      <FinishedConcept
        className={className}
        concept={concept}
        color={color}
        onClick={conceptEditing}
      />
    )
  }

  return (
    <div className={className}>
      <section
        className="hero is-bold concept-header-desktop"
        style={{ backgroundColor: color }}
      >
        <div className="hero-body">
          <div className="center horizontal">
            <h3 className="title">{concept.name}</h3>
            <div className="concept-icon">
              <img
                style={{ height: '75px', width: '75px' }}
                src={require(`../../assets/survey_icons/${concept.icon_name}`)}
                alt={concept.name}
                height="75"
                width="75"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="center" style={{ paddingTop: '1.5em' }}>
        <h5 className="title is-5">
          How would you describe your {concept.name}?
        </h5>
        <RatingSlider
          rating={concept.rating}
          onChange={rating => ratingChanged(concept.id, rating)}
          ratingText={concept.rating_text}
        />
      </section>
      <section className="section concept-challenges">
        <h5 className="title is-5">Check all that apply</h5>
        <CheckboxList
          challenges={concept.challenges}
          onChange={e => challengeChanged(concept.id, e.target.dataset.id)}
          color={color}
        />
      </section>
      <NeedSelection
        needs={concept.needs}
        onChange={needIdx => needChanged(concept.id, needIdx)}
        name={concept.name}
      />
      <div className="concept-btn-container center">
        {renderBtn(concept, color, conceptFinished, nextConceptId)}
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    nextConceptId: state.surveyState.nextConceptId
  }
}

export default connect(
  mapStateToProps,
  {
    ratingChanged,
    challengeChanged,
    needChanged,
    conceptFinished,
    conceptEditing
  }
)(Concept)
